// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormRadioButton_container__r1W34{
    display: flex;
    align-items: center;
    height: 57px;
    padding: 0 15px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
    cursor: pointer;
}
.FormRadioButton_selected__L\\+U5B{
    outline: 5px solid #BAD8D4;
}
.FormRadioButton_outerCircle__1loT\\+{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    border: 1px solid #1F363D33;
    border-radius: 50%;
}
.FormRadioButton_innerCircle__3etYz{
    display: none;
    height: 12px;
    width: 12px;
    background-color: #40798C;
    border-radius: 50%;
}
.FormRadioButton_selected__L\\+U5B .FormRadioButton_innerCircle__3etYz{
    display: inline-block;
}
.FormRadioButton_text__7pJlt{
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
}
.FormRadioButton_selected__L\\+U5B .FormRadioButton_text__7pJlt{
    color: #40798C;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormRadioButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,2BAA2B;IAC3B,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,eAAe;IACf,mCAAmC;AACvC;AACA;IACI,cAAc;AAClB","sourcesContent":[".container{\n    display: flex;\n    align-items: center;\n    height: 57px;\n    padding: 0 15px;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 500px;\n    cursor: pointer;\n}\n.selected{\n    outline: 5px solid #BAD8D4;\n}\n.outerCircle{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 24px;\n    width: 24px;\n    margin-right: 10px;\n    border: 1px solid #1F363D33;\n    border-radius: 50%;\n}\n.innerCircle{\n    display: none;\n    height: 12px;\n    width: 12px;\n    background-color: #40798C;\n    border-radius: 50%;\n}\n.selected .innerCircle{\n    display: inline-block;\n}\n.text{\n    color: #1F363D;\n    font-size: 16px;\n    font-family: 'Gotham Medium', serif;\n}\n.selected .text{\n    color: #40798C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormRadioButton_container__r1W34`,
	"selected": `FormRadioButton_selected__L+U5B`,
	"outerCircle": `FormRadioButton_outerCircle__1loT+`,
	"innerCircle": `FormRadioButton_innerCircle__3etYz`,
	"text": `FormRadioButton_text__7pJlt`
};
export default ___CSS_LOADER_EXPORT___;
