// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhotoModal_modal__oCBuq{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 400;
}
.PhotoModal_container__fMkqm{
    position: relative;
    width: 85%;
    padding: 20px 25px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.PhotoModal_close__s3ZAv{
    position: absolute;
    top: 0;
    right: 0;
    height: 12px;
    width: 12px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
}
@media(max-width: 600px){
    .PhotoModal_container__fMkqm{
        padding: 12px 15px;
    }
    .PhotoModal_close__s3ZAv{
        padding: 10px;
    }
}
.PhotoModal_image__s2ICb{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/PhotoModal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,WAAW;IACX,mCAAmC;IACnC,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,yBAAyB;IACzB,2BAA2B;IAC3B,mBAAmB;IACnB,kCAAkC;AACtC;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,YAAY;IACZ,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,aAAa;IACjB;AACJ;AACA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".modal{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    background-color:rgba(0, 0, 0, 0.5);\n    z-index: 400;\n}\n.container{\n    position: relative;\n    width: 85%;\n    padding: 20px 25px;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 25px;\n    box-shadow: 0 3px 12px 0 #00000012;\n}\n.close{\n    position: absolute;\n    top: 0;\n    right: 0;\n    height: 12px;\n    width: 12px;\n    padding: 20px;\n    background-color: #FFFFFF;\n    border-radius: 50%;\n    cursor: pointer;\n}\n@media(max-width: 600px){\n    .container{\n        padding: 12px 15px;\n    }\n    .close{\n        padding: 10px;\n    }\n}\n.image{\n    height: 100%;\n    width: 100%;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `PhotoModal_modal__oCBuq`,
	"container": `PhotoModal_container__fMkqm`,
	"close": `PhotoModal_close__s3ZAv`,
	"image": `PhotoModal_image__s2ICb`
};
export default ___CSS_LOADER_EXPORT___;
