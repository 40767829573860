// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Updates_topRow__hfF3K{
    display: flex;
}
.Updates_header__rzcyY{
    margin-bottom: 15px;
    color: #1F363D;
    font-size: 32px;
    font-family: 'Gotham Bold', serif;
}
.Updates_sortOrder__DIVpG{
    margin-top: 20px;
    margin-left: auto;
}
@media(max-width: 600px){
    .Updates_header__rzcyY{
        font-size: 24px;
    }
    .Updates_sortOrder__DIVpG{
        margin-top: 10px;
    }
}
.Updates_button__8PRwU{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.Updates_updatesContainer__K3\\+2d{
    margin-top: 15px;
    margin-left: 5px;
}
@media(max-width: 600px){
    .Updates_updatesContainer__K3\\+2d{
        margin-top: 15px;
    }
}
.Updates_updates__cAqdx{
    margin-top: 10px;
    padding-left: 25px;
    border-left: 2px solid #ECECEC;
}
.Updates_update__sX16J{
    margin-bottom: 15px;
}
.Updates_noUpdates__oaAf1{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    width: 300px;
    text-align: center;
}
@media(max-width: 600px){
    .Updates_noUpdates__oaAf1{
        margin-top: 20px;
    }
}
.Updates_bellQuestionMark__SZnJ9{
    height: 45px;
    width: 45px;
}
.Updates_noUpdatesText__DWL6P{
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1F363D;
    font-size: 20px;
    font-family: 'Gotham Bold', serif;
}`, "",{"version":3,"sources":["webpack://./src/components/fundraiser/Updates.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,iCAAiC;AACrC;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,8BAA8B;AAClC;AACA;IACI,mBAAmB;AACvB;AACA;IACI,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,iCAAiC;AACrC","sourcesContent":[".topRow{\n    display: flex;\n}\n.header{\n    margin-bottom: 15px;\n    color: #1F363D;\n    font-size: 32px;\n    font-family: 'Gotham Bold', serif;\n}\n.sortOrder{\n    margin-top: 20px;\n    margin-left: auto;\n}\n@media(max-width: 600px){\n    .header{\n        font-size: 24px;\n    }\n    .sortOrder{\n        margin-top: 10px;\n    }\n}\n.button{\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n}\n.updatesContainer{\n    margin-top: 15px;\n    margin-left: 5px;\n}\n@media(max-width: 600px){\n    .updatesContainer{\n        margin-top: 15px;\n    }\n}\n.updates{\n    margin-top: 10px;\n    padding-left: 25px;\n    border-left: 2px solid #ECECEC;\n}\n.update{\n    margin-bottom: 15px;\n}\n.noUpdates{\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 60px;\n    width: 300px;\n    text-align: center;\n}\n@media(max-width: 600px){\n    .noUpdates{\n        margin-top: 20px;\n    }\n}\n.bellQuestionMark{\n    height: 45px;\n    width: 45px;\n}\n.noUpdatesText{\n    margin-top: 15px;\n    margin-bottom: 15px;\n    color: #1F363D;\n    font-size: 20px;\n    font-family: 'Gotham Bold', serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRow": `Updates_topRow__hfF3K`,
	"header": `Updates_header__rzcyY`,
	"sortOrder": `Updates_sortOrder__DIVpG`,
	"button": `Updates_button__8PRwU`,
	"updatesContainer": `Updates_updatesContainer__K3+2d`,
	"updates": `Updates_updates__cAqdx`,
	"update": `Updates_update__sX16J`,
	"noUpdates": `Updates_noUpdates__oaAf1`,
	"bellQuestionMark": `Updates_bellQuestionMark__SZnJ9`,
	"noUpdatesText": `Updates_noUpdatesText__DWL6P`
};
export default ___CSS_LOADER_EXPORT___;
