import { useState } from 'react';
import classNames from 'classnames';
import badgeBlack from '../../assets/images/badge-black.png';
import badgeTeal from '../../assets/images/badge-teal.png';
import buildingBlack from '../../assets/images/building-black.png';
import buildingTeal from '../../assets/images/building-teal.png';
import documentQuestionMarkBlack from '../../assets/images/document-question-mark-black.png';
import documentQuestionMarkTeal from '../../assets/images/document-question-mark-teal.png';
import shieldBlack from '../../assets/images/shield-black.png';
import shieldTeal from '../../assets/images/shield-teal.png';
import downCaret from '../../assets/images/down-caret.png';
import upCaret from '../../assets/images/up-caret.png';
import styles from './MenuDropdown.module.css';

const MenuDropdown = (props) => {
	const [open, setOpen] = useState(false);

	const update = (view) => {
		props.update(view);
		setOpen(false);
	};

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<div className={styles.button} onClick={() => setOpen(!open)}>
				{
					props.view === 'personal' && (
						<div>
							<img className={styles.icon} src={badgeTeal} alt="Selected Personal info"/>
							<span className={styles.label}>
								Personal info
							</span>
						</div>
					)
				}
				{
					props.view === 'organization' && (
						<div>
							<img className={styles.icon} src={buildingTeal} alt="Selected Organization info"/>
							<span className={styles.label}>
								Organization info
							</span>
						</div>
					)
				}
				{
					props.view === 'requests' && (
						<div>
							<img className={styles.icon} src={documentQuestionMarkTeal} alt="Selected Requests"/>
							<span className={styles.label}>
								Requests
							</span>
						</div>
					)
				}
				{
					props.view === 'security' && (
						<div>
							<img className={styles.icon} src={badgeTeal} alt="Selected Security"/>
							<span className={styles.label}>
								Security
							</span>
						</div>
					)
				}
				{
					!open && (
						<img className={styles.downCaret} src={downCaret} alt="Down Caret"/>
					)
				}
				{
					open && (
						<img className={styles.upCaret} src={upCaret} alt="Up Caret"/>
					)
				}
			</div>
			{
				open && (
					<div className={styles.options}>
						<div className={classNames(styles.option, {[styles.selected]: props.view === 'personal'})} onClick={() => update('personal')}>
							{
								props.view === 'personal' ? (
									<img className={styles.menuLinkIcon} src={badgeTeal} alt="Personal info"/>
								) : (
									<img className={styles.menuLinkIcon} src={badgeBlack} alt="Personal info"/>
								)
							}
							<span className={styles.menuLinkLabel}>
								Personal info
							</span>
						</div>
						<div className={classNames(styles.option, {[styles.selected]: props.view === 'organization'})} onClick={() => update('organization')}>
							{
								props.view === 'organization' ? (
									<img className={styles.menuLinkIcon} src={buildingTeal} alt="Organization info"/>
								) : (
									<img className={styles.menuLinkIcon} src={buildingBlack} alt="Organization info"/>
								)
							}
							<span className={styles.menuLinkLabel}>
								Organization info
							</span>
						</div>
						<div className={classNames(styles.option, {[styles.selected]: props.view === 'requests'})} onClick={() => update('requests')}>
							{
								props.view === 'requests' ? (
									<img className={styles.menuLinkIcon} src={documentQuestionMarkTeal} alt="Requests"/>
								) : (
									<img className={styles.menuLinkIcon} src={documentQuestionMarkBlack} alt="Requests"/>
								)
							}
							<span className={styles.menuLinkLabel}>
								Requests
							</span>
						</div>
						<div className={classNames(styles.option, {[styles.selected]: props.view === 'security'})} onClick={() => update('security')}>
							{
								props.view === 'security' ? (
									<img className={styles.menuLinkIcon} src={shieldTeal} alt="Security"/>
								) : (
									<img className={styles.menuLinkIcon} src={shieldBlack} alt="Security"/>
								)
							}
							<span className={styles.menuLinkLabel}>
								Security
							</span>
						</div>
					</div>
				)
			}
		</div>
	)
};

export default MenuDropdown;