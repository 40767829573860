import classNames from "classnames";
import clockCircleOrange from '../../assets/images/clock-circle-orange.png';
import checkmarkCircleGreen from '../../assets/images/checkmark-circle-green.png';
import xCircleRed from "../../assets/images/x-circle-red.png";
import styles from './RequestStatus.module.css';

const RequestStatus = (props) => {
	return (
		<div className={classNames(styles.container, props.className)}>
			<span className={styles.label}>
				Direct payout:
			</span>
			<span className={classNames(styles.status, styles[props.status])}>
				{
					props.status === 'requested' && (
						<img className={styles.clockCircleOrange} src={clockCircleOrange} alt="Requested"/>
					)
				}
				{
					props.status === 'approved' && (
						<img className={styles.checkmarkCircleGreen} src={checkmarkCircleGreen} alt="Approved"/>
					)
				}
				{
					props.status === 'rejected' && (
						<img className={styles.xCircleRed} src={xCircleRed} alt="Rejected"/>
					)
				}
				{props.status === 'requested' ? 'Pending' : props.status}
			</span>
		</div>
	)
};

export default RequestStatus;