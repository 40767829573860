// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comment_container__0AEqZ{
    padding-top: 10px;
    padding-bottom: 10px;
}
.Comment_name__K\\+iOe{
    margin-bottom: 5px;
    color: #1F363D;
    font-family: 'Gotham Medium', serif;
}
.Comment_info__89NrZ{
    display: flex;
    margin-bottom: 10px;
}
.Comment_coin__kB1QS{
    position: relative;
    top: 3px;
    height: 12.5px;
    width: 12.5px;
    margin-right: 5px;
}
.Comment_amount__yscJo{
    color: #1F363D;
    margin-right: 10px;
}
.Comment_dot__EpdWD{
    position: relative;
    top: 6px;
    height: 6px;
    width: 6px;
    margin-right: 10px;
    background-color: #1F363D;
    border-radius: 50%;
    opacity: 60%;
}
.Comment_datetime__5pv6B{
    color: #1F363D;
    font-size: 14px;
    opacity: 60%;
}
.Comment_comment__AiJYo{
    color: #1F363D;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/fundraiser/Comment.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,cAAc;IACd,mCAAmC;AACvC;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,cAAc;IACd,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,cAAc;IACd,eAAe;IACf,YAAY;AAChB;AACA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".container{\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n.name{\n    margin-bottom: 5px;\n    color: #1F363D;\n    font-family: 'Gotham Medium', serif;\n}\n.info{\n    display: flex;\n    margin-bottom: 10px;\n}\n.coin{\n    position: relative;\n    top: 3px;\n    height: 12.5px;\n    width: 12.5px;\n    margin-right: 5px;\n}\n.amount{\n    color: #1F363D;\n    margin-right: 10px;\n}\n.dot{\n    position: relative;\n    top: 6px;\n    height: 6px;\n    width: 6px;\n    margin-right: 10px;\n    background-color: #1F363D;\n    border-radius: 50%;\n    opacity: 60%;\n}\n.datetime{\n    color: #1F363D;\n    font-size: 14px;\n    opacity: 60%;\n}\n.comment{\n    color: #1F363D;\n    font-size: 14px;\n    line-height: 20px;\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Comment_container__0AEqZ`,
	"name": `Comment_name__K+iOe`,
	"info": `Comment_info__89NrZ`,
	"coin": `Comment_coin__kB1QS`,
	"amount": `Comment_amount__yscJo`,
	"dot": `Comment_dot__EpdWD`,
	"datetime": `Comment_datetime__5pv6B`,
	"comment": `Comment_comment__AiJYo`
};
export default ___CSS_LOADER_EXPORT___;
