import { useState, useEffect } from 'react';
import styles from './Personal.module.css';
import { getUserStripeLinkApi, createUserStripeAccountApi, updateUserApi } from '../../api/users.js';
import FormInput from '../form/FormInput.jsx';
import FormButton from '../form/FormButton.jsx';
import FormError from '../form/FormError.jsx';
import ImageSection from './ImageSection.jsx';
import PictureModal from '../modals/PictureModal.jsx';
import bank from "../../assets/images/bank.png";
import checkmarkCircleGreen from "../../assets/images/checkmark-circle-green.png";
import exclamationTriangle from "../../assets/images/exclamation-triangle.png";
import Button from "../Button";
import ButtonLink from "../ButtonLink";

const Personal = (props) => {
	const [imageBase64, setImageBase64] = useState('');
	const [showPictureModal, setShowPictureModal] = useState(false);
	const [stripeAccountCreated, setStripeAccountCreated] = useState(true);
	const [createStripeAccountInProgress, setCreateStripeAccountInProgress] = useState(false);
	const [stripePayoutsEnabled, setStripePayoutsEnabled] = useState(false);
	const [stripeLink, setStripeLink] = useState('');
	const [formData, setFormData] = useState({
		email: '',
		firstName: '',
		lastName: '',
		pictureUrl: ''
	});
	const initialErrorObject = {
		firstName: false,
		lastName: false,
		apiError: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		firstName: 'Missing first name',
		lastName: 'Missing last name'
	};
	const [saveInProgress, setSaveInProgress] = useState(false);

	const createUserStripeAccount = async () => {
		setCreateStripeAccountInProgress(true);
		const response = await createUserStripeAccountApi();
		const body = await response.json();
		if(response && response.ok){
			window.location = body.data.link;
		}
		setCreateStripeAccountInProgress(true);
	};

	const getUserStripeLink = async () => {
		const response = await getUserStripeLinkApi();
		const body = await response.json();
		if(response && response.ok){
			setStripePayoutsEnabled(body.data.payouts_enabled);
			setStripeLink(body.data.link);
		}
		else if(body.error === 'No Stripe ID found for user'){
			setStripeAccountCreated(false);
		}
	};

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(!formData[key] && key !== 'pictureUrl'){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setSaveInProgress(true);
		if(checkForEmptyInputs()){
			setSaveInProgress(false);
			return;
		}
		const response = await updateUserApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			window.location.reload();
		}
		else{
			const error = body?.error ? body.error : 'Update failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
			setSaveInProgress(false);
		}
	};

	useEffect(() => {
		if(props.user){
			setFormData({
				email: props.user?.email ? props.user.email : '',
				firstName: props.user?.first_name ? props.user.first_name : '',
				lastName: props.user?.last_name ? props.user.last_name : '',
				pictureUrl: props.user?.profile_picture_url ? props.user.profile_picture_url : ''
			});
			if(props.user.organization_id === null){
				getUserStripeLink();
			}
		}
	}, [props.user]);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	useEffect(() => {
		if(!stripeAccountCreated || stripeLink){
			if(window.location.hash === '#bank-info'){
				document.getElementById('bank-info').scrollIntoView();
			}
		}
	}, [stripeAccountCreated, stripeLink]);

	return (
		<form className={props.className} onSubmit={submit}>
			<ImageSection formData={formData} user={props.user} type={'personal'} setImageBase64={setImageBase64} setShowPictureModal={setShowPictureModal}/>
			<div className={styles.dualInputsRow}>
				<FormInput className={styles.name} label="First name" type="text" value={formData.firstName}
						   update={(event) => setFormData({...formData, firstName: event.target.value})} error={errorObject.firstName} required/>
				<FormInput className={styles.name} label="Last name" type="text" style={{marginLeft: 'auto'}} value={formData.lastName}
						   update={(event) => setFormData({...formData, lastName: event.target.value})} error={errorObject.lastName} required/>
			</div>
			<FormInput className={styles.singleLineInput} label="Email" type="email" value={formData.email} disabled={true}/>
			{
				!props.user.organizationId && (stripeLink || !stripeAccountCreated) && (
					<div id="bank-info" className={styles.bankInfoRow}>
						<span className={styles.bankInfoLabel}>
							<img className={styles.bankIcon} src={bank} alt="Bank"/>
							Payout Info
							{
								stripePayoutsEnabled && (
									<img className={styles.checkmark} src={checkmarkCircleGreen} alt="Checkmark" title="Payout setup complete"/>
								)
							}
							{
								!stripePayoutsEnabled && (
									<img className={styles.exclamation} src={exclamationTriangle} alt="Exclamation" title="Missing info for payouts"/>
								)
							}
						</span>
						{
							stripeAccountCreated && (
								<ButtonLink className={styles.bankInfoButton} href={stripeLink} target={stripePayoutsEnabled ? '_blank' : '_self'}
											text={stripePayoutsEnabled ? 'Update info' : 'Complete setup'}/>
							)
						}
						{
							!stripeAccountCreated && (
								<Button className={styles.bankInfoButton} text="Start setup" onClick={createUserStripeAccount} disabled={createStripeAccountInProgress}/>
							)
						}
					</div>
				)
			}
			{
				errorObject.apiError && (
					<FormError error={errorObject.apiError}/>
				)
			}
			<FormButton className={styles.button} text="Save changes" disabled={saveInProgress}/>
			{
				showPictureModal && (
					<PictureModal imageBase64={imageBase64} user={props.user} type="personal" update={(newPictureUrl) => setFormData({...formData, pictureUrl: newPictureUrl})}
								  close={() => setShowPictureModal(false)}/>
				)
			}
		</form>
	);
};

export default Personal;