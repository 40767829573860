const getUserApi = async () => {
	const token = localStorage.getItem('api-token');
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		}
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/user', options);
};

const getUserStripeLinkApi = async() => {
    const token = localStorage.getItem('api-token');
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        }
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/stripe/user', options);
};

const createUserStripeAccountApi = async() => {
	const token = localStorage.getItem('api-token');
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        }
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/stripe/user', options);
};

const updateUserApi = async(formData) => {
	const token = localStorage.getItem('api-token');
	const editedFormData = {
		first_name: formData.firstName,
		last_name: formData.lastName,
		profile_picture_url: formData.pictureUrl
	};
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		},
		body: JSON.stringify(editedFormData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/user', options);
};

const updateUserPasswordApi = async(formData) => {
	const token = localStorage.getItem('api-token');
	const editedFormData = {
		current_password: formData.currentPassword,
		new_password: formData.newPassword
	};
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		},
		body: JSON.stringify(editedFormData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/user/password', options);
};

export { getUserApi, getUserStripeLinkApi, createUserStripeAccountApi, updateUserApi, updateUserPasswordApi };