import { useState, useEffect } from 'react';
import classNames from 'classnames';
import emptyDrawer from '../../assets/images/empty-drawer.png';
import styles from './Requests.module.css';
import { getOrganizationRequestsApi } from '../../api/organizations';
import findArrayIndex from "../../utils/findArrayIndex";
import RequestCard from "./RequestCard";

const Requests = (props) => {
	const [selectedStatus, setSelectedStatus] = useState('requested');
	const [pendingRequests, setPendingRequests] = useState([]);
	const [approvedRequests, setApprovedRequests] = useState([]);
	const [rejectedRequests, setRejectedRequests] = useState([]);

	const getRequests = async () => {
		const response = await getOrganizationRequestsApi();
		const body = await response.json();
		if(response && response.ok){
			let pendingRequestsArray = [];
			let approvedRequestsArray = [];
			let rejectedRequestsArray = [];
			for(let i = 0; i < body.data.organization_requests.length; i++){
				const request = body.data.organization_requests[i];
				if(request.direct_payment_status === 'requested'){
					pendingRequestsArray.push(request);
				}
				else if(request.direct_payment_status === 'approved'){
					approvedRequestsArray.push(request);
				}
				else if(request.direct_payment_status === 'rejected'){
					rejectedRequestsArray.push(request);
				}
			}
			setPendingRequests(pendingRequestsArray);
			setApprovedRequests(approvedRequestsArray);
			setRejectedRequests(rejectedRequestsArray);
		}
	};

	const updateStatusToApproved = (fundraiserId) => {
		const index = findArrayIndex(pendingRequests, 'id', fundraiserId);
		const newPendingRequests = pendingRequests.slice();
		const updatedRequest = newPendingRequests.splice(index, 1);
		updatedRequest[0].direct_payment_status = 'approved';
		setPendingRequests(newPendingRequests);
		setApprovedRequests([...approvedRequests, ...updatedRequest]);
	};

	const updateStatusToRejected = (fundraiserId) => {
		const index = findArrayIndex(pendingRequests, 'id', fundraiserId);
		const newPendingRequests = pendingRequests.slice();
		const updatedRequest = newPendingRequests.splice(index, 1);
		updatedRequest[0].direct_payment_status = 'rejected';
		setPendingRequests(newPendingRequests);
		setRejectedRequests([...rejectedRequests, ...updatedRequest]);
	};

	const sortByOldest = (a, b) => {
		if(a.created_at < b.created_at){
			return -1;
		}
		else if(a.created_at > b.created_at){
			return 1;
		}
		return 0;
	};

	const sortByNewest = (a, b) => {
		if(a.created_at > b.created_at){
			return -1;
		}
		else if(a.created_at < b.created_at){
			return 1;
		}
		return 0;
	};

	useEffect(() => {
		getRequests();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={props.className}>
			<div className={styles.menu}>
				<button className={classNames(styles.menuLink, {[styles.selected]: selectedStatus === 'requested'})} onClick={() => setSelectedStatus('requested')}>
					Requested
				</button>
				<button className={classNames(styles.menuLink, {[styles.selected]: selectedStatus === 'approved'})} onClick={() => setSelectedStatus('approved')}>
					Approved
				</button>
				<button className={classNames(styles.menuLink, {[styles.selected]: selectedStatus === 'rejected'})} onClick={() => setSelectedStatus('rejected')}>
					Rejected
				</button>
			</div>
			<div className={styles.views}>
				{
					selectedStatus === 'requested' && (
						pendingRequests.sort(sortByOldest).map(request => (
							<RequestCard request={request} updateStatusToApproved={updateStatusToApproved} updateStatusToRejected={updateStatusToRejected} key={request.id}/>
						))
					)
				}
				{
					selectedStatus === 'approved' && (
						approvedRequests.sort(sortByNewest).map(request => (
							<RequestCard request={request} key={request.id}/>
						))
					)
				}
				{
					selectedStatus === 'rejected' && (
						rejectedRequests.sort(sortByNewest).map(request => (
							<RequestCard request={request} key={request.id}/>
						))
					)
				}
				{
					((selectedStatus === 'requested' && pendingRequests.length === 0) ||
					 (selectedStatus === 'approved' && approvedRequests.length === 0) ||
					 (selectedStatus === 'rejected' && rejectedRequests.length === 0)) && (
						<div className={styles.noRequests}>
							<img className={styles.emptyDrawer} src={emptyDrawer} alt="Empty Drawer"/>
							<div className={styles.noRequestsText}>
								Nothing found
							</div>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default Requests;