// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestStatus_container__W-4ba{
    display: flex;
    align-items: center;
    width: calc(100% - 50px);
    padding: 15px 25px 15px 25px;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.RequestStatus_label__PuC\\+6{
    color: #40798C;
    font-size: 16px;
    font-family: 'Gotham Bold', serif;
}
.RequestStatus_status__DwoJE{
    padding: 7px 12px 9px 12px;
    margin-left: auto;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    border: 1px solid;
    border-radius: 18px;
}
.RequestStatus_requested__CTDVB{
    color: #FF9900;
    background-color: #FFF6E9;
    border-color: #FF9900;
}
.RequestStatus_approved__H7xi\\+{
    color: #29A462;
    background-color: #E6FFF1;
    text-transform: capitalize;
    border-color: #29A462;
}
.RequestStatus_rejected__JMwAF{
    color: #EB443F;
    background-color: #FFEFEF;
    text-transform: capitalize;
    border-color: #EB443F;
}
.RequestStatus_clockCircleOrange__KOLyJ, .RequestStatus_checkmarkCircleGreen__jo4NH, .RequestStatus_xCircleRed__apJip{
    position: relative;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}
.RequestStatus_clockCircleOrange__KOLyJ{
    top: 3.5px
}
.RequestStatus_checkmarkCircleGreen__jo4NH{
    top: 3px;
}
.RequestStatus_xCircleRed__apJip{
    top: 2.5px;
}`, "",{"version":3,"sources":["webpack://./src/components/fundraiser/RequestStatus.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,wBAAwB;IACxB,4BAA4B;IAC5B,2BAA2B;IAC3B,mBAAmB;IACnB,kCAAkC;AACtC;AACA;IACI,cAAc;IACd,eAAe;IACf,iCAAiC;AACrC;AACA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,eAAe;IACf,mCAAmC;IACnC,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,0BAA0B;IAC1B,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,0BAA0B;IAC1B,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;AACA;IACI;AACJ;AACA;IACI,QAAQ;AACZ;AACA;IACI,UAAU;AACd","sourcesContent":[".container{\n    display: flex;\n    align-items: center;\n    width: calc(100% - 50px);\n    padding: 15px 25px 15px 25px;\n    border: 1px solid #1F363D1A;\n    border-radius: 25px;\n    box-shadow: 0 3px 12px 0 #00000012;\n}\n.label{\n    color: #40798C;\n    font-size: 16px;\n    font-family: 'Gotham Bold', serif;\n}\n.status{\n    padding: 7px 12px 9px 12px;\n    margin-left: auto;\n    font-size: 14px;\n    font-family: 'Gotham Medium', serif;\n    border: 1px solid;\n    border-radius: 18px;\n}\n.requested{\n    color: #FF9900;\n    background-color: #FFF6E9;\n    border-color: #FF9900;\n}\n.approved{\n    color: #29A462;\n    background-color: #E6FFF1;\n    text-transform: capitalize;\n    border-color: #29A462;\n}\n.rejected{\n    color: #EB443F;\n    background-color: #FFEFEF;\n    text-transform: capitalize;\n    border-color: #EB443F;\n}\n.clockCircleOrange, .checkmarkCircleGreen, .xCircleRed{\n    position: relative;\n    height: 16px;\n    width: 16px;\n    margin-right: 5px;\n}\n.clockCircleOrange{\n    top: 3.5px\n}\n.checkmarkCircleGreen{\n    top: 3px;\n}\n.xCircleRed{\n    top: 2.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RequestStatus_container__W-4ba`,
	"label": `RequestStatus_label__PuC+6`,
	"status": `RequestStatus_status__DwoJE`,
	"requested": `RequestStatus_requested__CTDVB`,
	"approved": `RequestStatus_approved__H7xi+`,
	"rejected": `RequestStatus_rejected__JMwAF`,
	"clockCircleOrange": `RequestStatus_clockCircleOrange__KOLyJ`,
	"checkmarkCircleGreen": `RequestStatus_checkmarkCircleGreen__jo4NH`,
	"xCircleRed": `RequestStatus_xCircleRed__apJip`
};
export default ___CSS_LOADER_EXPORT___;
