// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Requests_menu__yfqxn{
    display: flex;
    border-bottom: 1px solid #1F363D1A;
}
.Requests_menuLink__UHaMy{
    display: inline-block;
    width: 33.33%;
    padding-bottom: 12px;
    color: #1F363D;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    border: none;
    cursor: pointer;
}
.Requests_menuLink__UHaMy:focus-visible{
    outline: none;
}
.Requests_selected__zBTWR{
    color: #274C77;
    border-bottom: 3px solid #274C77;
}
.Requests_noRequests__B8wtA{
    margin-top: 150px;
}
.Requests_emptyDrawer__p6ai7{
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 60px;
    width: 60px;
}
.Requests_noRequestsText__zB12T{
    width: 100%;
    margin-top: 15px;
    color: #1F363D;
    text-align: center;
    font-size: 24px;
    font-family: 'Gotham Bold', serif;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/Requests.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,qBAAqB;IACrB,aAAa;IACb,oBAAoB;IACpB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,mCAAmC;IACnC,YAAY;IACZ,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,cAAc;IACd,gCAAgC;AACpC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC","sourcesContent":[".menu{\n    display: flex;\n    border-bottom: 1px solid #1F363D1A;\n}\n.menuLink{\n    display: inline-block;\n    width: 33.33%;\n    padding-bottom: 12px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    text-align: center;\n    font-size: 14px;\n    font-family: 'Gotham Medium', serif;\n    border: none;\n    cursor: pointer;\n}\n.menuLink:focus-visible{\n    outline: none;\n}\n.selected{\n    color: #274C77;\n    border-bottom: 3px solid #274C77;\n}\n.noRequests{\n    margin-top: 150px;\n}\n.emptyDrawer{\n    display: block;\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n    height: 60px;\n    width: 60px;\n}\n.noRequestsText{\n    width: 100%;\n    margin-top: 15px;\n    color: #1F363D;\n    text-align: center;\n    font-size: 24px;\n    font-family: 'Gotham Bold', serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `Requests_menu__yfqxn`,
	"menuLink": `Requests_menuLink__UHaMy`,
	"selected": `Requests_selected__zBTWR`,
	"noRequests": `Requests_noRequests__B8wtA`,
	"emptyDrawer": `Requests_emptyDrawer__p6ai7`,
	"noRequestsText": `Requests_noRequestsText__zB12T`
};
export default ___CSS_LOADER_EXPORT___;
