import { useState } from "react";
import classNames from "classnames";
import Bugsnag from "@bugsnag/js";
import dollarCircle from '../../assets/images/dollar-circle.png';
import checkmarkCircleGreen from '../../assets/images/checkmark-circle-green.png';
import xCircleRed from '../../assets/images/x-circle-red.png';
import styles from './RequestCard.module.css';
import { updateFundraiserRequestApi } from "../../api/fundraisers";
import parseDatetimeText from "../../utils/parseDatetimeText";
import formatNumber from "../../utils/formatNumber";
import Button from "../Button";

const RequestCard = (props) => {
	const [approveOrRejectInProgress, setApproveOrRejectInProgress] = useState(false);

	const updateRequest = async (status) => {
		setApproveOrRejectInProgress(true);
		const updateRequestResponse = await updateFundraiserRequestApi(props.request.id, status);
		let body;
        try{
            body = await updateRequestResponse.json();
        }
        catch(e){}
        if(updateRequestResponse && updateRequestResponse.ok){
			if(status === 'approved'){
				props.updateStatusToApproved(props.request.id);
			}
			else if(status === 'rejected'){
				props.updateStatusToRejected(props.request.id);
			}
        }
		else{
			Bugsnag.notify(body?.error ? body.error : 'Fundraiser request update failed');
			alert('Something went wrong. Request was not updated');
		}
		setApproveOrRejectInProgress(false);
	};

	return (
		<div className={styles.container}>
			<div className={styles.firstRow}>
				{
					props.request.profile_picture_url && (
						<img className={styles.organizerPicture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + props.request.profile_picture_url} alt="Organizer"/>
					)
				}
				{
					!props.request.profile_picture_url && (
						<span className={styles.organizerPlaceholder}>
							{props.request.first_name.slice(0, 1).toUpperCase() + ' ' + props.request.last_name.slice(0, 1).toUpperCase()}
						</span>
					)
				}
				<span className={styles.organizerName}>
					{props.request.first_name} {props.request.last_name}
				</span>
				<span className={styles.createdAt}>
					{parseDatetimeText(props.request.created_at)}
				</span>
			</div>
			<div className={styles.secondRow}>
				<a className={styles.name} href={'/fundraiser/' + props.request.id} target="_blank">
					{props.request.name}
				</a>
			</div>
			<div className={classNames(styles.thirdRow, {[styles.requested]: props.request.direct_payment_status === 'requested'})}>
				<span className={styles.goal}>
					<img className={styles.dollarCircle} src={dollarCircle} alt="Dollar Sign"/>
					<span className={styles.goalLabel}>
						Goal:
					</span>
					<span className={styles.goalAmount}>
						${formatNumber(props.request.goal_amount)}
					</span>
				</span>
				{
					props.request.direct_payment_status === 'requested' && (
						<span className={styles.buttons}>
							<Button className={styles.rejectButton} text="Reject" onClick={() => updateRequest('rejected')} disabled={approveOrRejectInProgress}/>
							<Button className={styles.approveButton} text="Approve" dark={true} onClick={() => updateRequest('approved')} disabled={approveOrRejectInProgress}/>
						</span>
					)
				}
				{
					['approved', 'rejected'].indexOf(props.request.direct_payment_status) > -1 && (
						<span className={classNames(styles.status, styles[props.request.direct_payment_status])}>
							{
								props.request.direct_payment_status === 'approved' && (
									<span>
										<img className={styles.checkmarkCircleGreen} src={checkmarkCircleGreen} alt="Approved"/>
										Approved
									</span>
								)
							}
							{
								props.request.direct_payment_status === 'rejected' && (
									<span>
										<img className={styles.xCircleRed} src={xCircleRed} alt="Rejected"/>
										Rejected
									</span>
								)
							}
						</span>
					)
				}
			</div>
		</div>
	)
};

export default RequestCard;