import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import classNames from 'classnames';
import moment from 'moment';
import exclamationCircleRed from '../assets/images/exclamation-circle-red.png';
import clockCircleOrange from '../assets/images/clock-circle-orange.png';
import checkmarkCircleGreen from '../assets/images/checkmark-circle-green.png';
import xCircleRed from '../assets/images/x-circle-red.png';
import moneyCoins from '../assets/images/coins.png'
import styles from './FundraiserCard.module.css';
import getFundraiserStatus from "../utils/getFundraiserStatus";
import formatNumber from "../utils/formatNumber";

const FundraiserCard = (props) => {
	const { user } = useOutletContext();
	const [status, setStatus] = useState('');
	const [statusClassName, setStatusClassName] = useState('');
	const [progressPercent, setProgressPercent] = useState(0);
	const showRequestPendingStatus = user.organization_id === props.fundraiser.organization_id && props.fundraiser.direct_payment_status === 'requested';
	const showRequestStatus = user.organization_id !== props.fundraiser.organization_id && props.fundraiser.direct_payment_status !== null;

	const getStatus = () => {
        const statusObject = getFundraiserStatus(props.fundraiser.start_at, props.fundraiser.end_at)
        setStatus(statusObject.status);
        setStatusClassName(statusObject.statusClassName);
	};

	const getProgressPercent = () => {
		if(!props.fundraiser.total_donations_amount){
			return;
		}
		setProgressPercent(Math.min((props.fundraiser.total_donations_amount / props.fundraiser.goal_amount) * 100, 100));
	};

	useEffect(() => {
		getStatus();
		getProgressPercent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<h3 className={styles.name}>
				{props.fundraiser.name}
			</h3>
			{
				showRequestPendingStatus && (
					<div className={styles.requestPendingStatus}>
						<img className={styles.exclamationCircleRed} src={exclamationCircleRed} alt="Exclamation"/>
						Direct payout requested
					</div>
				)
			}
			{
				!showRequestPendingStatus && (
					<div className={classNames(styles.status, styles[statusClassName])}>
						{status}
					</div>
				)
			}
			<div className={styles.progress}>
				<span className={styles.label}>
					Progress Towards Goal
				</span>
				<div className={styles.progressBar}>
					<div className={styles.progressMade} style={{width: progressPercent + '%'}}/>
				</div>
			</div>
			<div className={styles.firstInfoRow}>
				<div>
					<span className={styles.label}>
						Deadline
					</span>
					<span className={styles.value}>
						{moment(props.fundraiser.end_at).format('MMM Do, YYYY')}
					</span>
				</div>
				<div className={styles.startAt}>
					<span className={styles.label}>
						Start Date
					</span>
					<span className={styles.value}>
						{moment(props.fundraiser.start_at).format('MMM Do, YYYY')}
					</span>
				</div>
			</div>
			<div className={classNames(styles.secondInfoRow, {[styles.showRequestStatus]: showRequestStatus})}>
				<div className={styles.totalDonations}>
					<span className={styles.label}>
						Donated
					</span>
					<span className={styles.value}>
						{props.fundraiser.total_donations_amount ? '$' + formatNumber(props.fundraiser.total_donations_amount) : 'No Donations'}
					</span>
				</div>
				{
					showRequestStatus && (
						<div className={styles.requestStatus}>
							<span className={styles.label}>
								Direct payout
							</span>
							<span className={classNames(styles.value, styles[props.fundraiser.direct_payment_status])}>
								{
									props.fundraiser.direct_payment_status === 'requested' && (
										<img className={styles.clockCircleOrange} src={clockCircleOrange} alt="Requested"/>
									)
								}
								{
									props.fundraiser.direct_payment_status === 'approved' && (
										<img className={styles.checkmarkCircleGreen} src={checkmarkCircleGreen} alt="Approved"/>
									)
								}
								{
									props.fundraiser.direct_payment_status === 'rejected' && (
										<img className={styles.xCircleRed} src={xCircleRed} alt="Rejected"/>
									)
								}
								{props.fundraiser.direct_payment_status === 'requested' ? 'Pending' : props.fundraiser.direct_payment_status}
							</span>
						</div>
					)
				}
			</div>
			<a className={styles.buttonLink} href={'fundraiser/' + props.fundraiser.id}>
				<img className={styles.moneyCoins} src={moneyCoins} alt="Money Coins"/>
				Manage
			</a>
		</div>
	)
};

export default FundraiserCard;